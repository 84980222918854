<template>
<div class="content-panel">
  <el-row :gutter="10" type="flex" justify="space-between" align="middle" class="breadcrumb-wrap">
    <el-col :span="20">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">{{msg('首页')}}</el-breadcrumb-item>
        <el-breadcrumb-item>{{msg('异常面单管理')}}</el-breadcrumb-item>
      </el-breadcrumb>
    </el-col>
    <el-col :span="4" style="text-align:right;">
      <el-button type="success" size="small" icon="el-icon-refresh" @click="refresh()" circle></el-button>
    </el-col>
  </el-row>

  <el-row :gutter="10"  class="query-wrap" v-if="hasPermission('expErr:add')">
    <el-col :xs="12" :sm="8" :lg="6" :xl="4">
        <el-input v-if="!autoPrint"  :placeholder="msg('订单编号')" v-model="code" size="small">
          <i slot="prefix" class="el-input__icon el-icon-tickets"></i>
        </el-input>
        <el-input v-else :placeholder="msg('订单编号')" ref="inputer1" @keyup.native="autoSearch" v-model="inputCode" size="small">
          <i slot="prefix" class="el-input__icon el-icon-tickets"></i>
        </el-input>
    </el-col>
    <el-col :xs="12" :sm="8" :lg="6" :xl="4">
        <el-input :placeholder="msg('异常原因')" v-model="remark" type="text" size="small"></el-input>
    </el-col>
    <el-col :xs="12" :sm="8" :lg="6" :xl="4">
        <el-select v-model="errType" size="small" class="query-select" placeholder="异常类型" clearable>
          <el-option v-for="d in dicts.expErrTypes1" :label="d.label" :value="d.value" :key="d.value"></el-option>
        </el-select>
    </el-col>
    <el-col :xs="24">
        <el-switch v-model="autoPrint" active-color="#13ce66" inactive-color="#ff4949" active-text="扫码输入" inactive-text="手动输入">
        </el-switch>
        <el-button type="success" @click="add()" v-if="hasPermission('expErr:add')" size="mini" style="margin-left: 15px; ">
          <i class="el-icon-plus" style="display:inline-block;margin-right:4px; "></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('点击保存')}}</span>
          </transition>
        </el-button>
    </el-col>
  </el-row>

  <el-row :gutter="10"  class="query-wrap">
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
        <el-input  :placeholder="msg('订单编号')" v-model="query.code" size="small">
          <i slot="prefix" class="el-input__icon el-icon-tickets"></i>
        </el-input>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
        <el-input  :placeholder="msg('物流厂商')" v-model="query.company" size="small">
          <i slot="prefix" class="el-input__icon el-icon-user"></i>
        </el-input>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
      <el-select v-model="query.resolve" size="small" class="query-select" placeholder="处理状态" clearable>
        <el-option v-for="(d, idx) in dicts.processes" :label="d" :value="idx" :key="idx"></el-option>
      </el-select>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
       <el-date-picker  v-model="query.Date"  type="daterange"  :range-separator="msg('至')" 
            :start-placeholder="msg('创建日期')"  value-format="yyyy-MM-dd"
            end-placeholder=""
            size="small"
          >
        </el-date-picker>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
        <el-input  :placeholder="msg('店铺名')" v-model="query.shopOwner" size="small">
          <i slot="prefix" class="el-input__icon el-icon-user"></i>
        </el-input>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
        <el-select v-model="query.errType" size="small" class="query-select" placeholder="异常类型" clearable>
          <el-option v-for="d in dicts.expErrTypes1" :label="d.label" :value="d.value" :key="d.value"></el-option>
        </el-select>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
      <el-button-group>
        <el-button type="success" @click="getErrss(true)" size="mini">
          <i class="el-icon-search" style="display:inline-block;margin-right:4px; "></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('搜索')}}</span>
          </transition>
        </el-button>
        <el-button type="danger" size="mini" @click="deleted()" v-if="hasPermission('expErr:delete')">
          <i class="el-icon-delete" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('删除')}}</span>
          </transition>
        </el-button>
        <el-button type="success" size="mini" @click="$refs.dataTable.exportData({ type: 'xlsx',filename: msg('异常面单'),sheetName: 'Sheet1' })" v-if="hasPermission('express:download')">
          <i class="el-icon-download" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('导出')}}</span>
          </transition>
        </el-button>
      </el-button-group>
    </el-col>
  </el-row>
  
  <div class="table-wrap">
    <vxe-table style="width: 100%" :height="docHeight" ref="dataTable"
      border="inner" size="small"  stripe resizable  :data="errs" 
      show-overflow 
      highlight-hover-row highlight-current-row highlight-hover-column highlight-current-column
    >
      <vxe-table-column type="checkbox" width="40" :fixed="isMinScreen?null: 'left'"></vxe-table-column>
      <vxe-table-column field="id" :title="msg('ID')"  min-width="90" :fixed="isMinScreen ? null : 'left'" 
          sortable :filters="[{data: {type: 'has', isCase: false, name: ''}}]" :filter-render="{name: 'FilterComplex'}"
      ></vxe-table-column>
      <vxe-table-column field="created" :title="msg('通报时间')" min-width="120"
          sortable :filters="[{data: []}]" :filter-render="{name: 'ElDatePicker', props: {type: 'daterange', rangeSeparator: '至', startPlaceholder: '开始日期', endPlaceholder: '结束日期'}}"
      ></vxe-table-column>
      <vxe-table-column field="orderCode" :title="msg('货单号')" min-width="150" 
          sortable :filters="[{data: null}]" :filter-render="{name: 'FilterInput'}"
      ></vxe-table-column>
      <vxe-table-column field="company" :title="msg('物流厂商')" min-width="120" 
        sortable :filters="dicts.expCompanies"
      ></vxe-table-column>
      <vxe-table-column field="shopOwner" :title="msg('店铺')" min-width="120" 
        sortable :filters="[{data: null}]" :filter-render="{name: 'FilterInput'}"
      ></vxe-table-column>
      <vxe-table-column field="remark" :title="msg('异常原因')" min-width="120" 
        sortable :filters="[{data: null}]" :filter-render="{name: 'FilterInput'}"
      ></vxe-table-column>
      <vxe-table-column field="errType" :title="msg('异常类型')" min-width="130" 
        sortable :filters="dicts.expErrTypes1"
      >
        <template v-slot="{ row }">
          <el-tag type="warning" disable-transitions>{{dicts.expErrTypes[row.errType]}}</el-tag>
        </template>
      </vxe-table-column>
      <vxe-table-column field="fullname" :title="msg('归属人')" min-width="120" 
        sortable :filters="[{data: null}]" :filter-render="{name: 'FilterInput'}"
      ></vxe-table-column>
      <vxe-table-column field="resolve" :title="msg('是否处理')" min-width="130" 
        sortable :filters="dicts.processes1"
      >
        <template v-slot="{ row }">
          <span>
            <el-tag :type="dicts.cssTypes[row.resolve]" disable-transitions>
              {{dicts.processes[row.resolve]}}
            </el-tag>
          </span>
        </template>
      </vxe-table-column>
    </vxe-table>

    <el-pagination id="xpagination"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :background="true"
      :current-page.sync="currentPage"
      :page-sizes="[10, 20,50, 100, 200, 300, 400]"
      :page-size="query.length"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div>

</div>
</template> 

<script>
// @ is an alias to /src

export default {
  name: 'OrderRepeat',
  components: {
  },
  mounted:function(){
    this.getErrss();
    var _this = this;
  },
  created(){
      this.query.resolve = 0
  },
  data(){
    return{
        query:{  //查询条件
          start: 0,
          length: 10,
          Date: [], 
          code: "",
          company: "",
          resolve: null,
          shopOwner:null,
          errType:null,
        },
        currentPage: 1,   //分页的当前页
        orderRepeats: [],   //表格数据列表
        dglogTitle: this.msg('编辑商品名'),
        isQueryExpend: this.$store.state.isQueryExpend,  //查询框是否展开
        total: 0,//总记录数,
        currId: null,   //当前列表中选中的id
        dialogTableVisible: false, //编辑详情的隐藏与显示,
        columns: [],  //字段是否显示
        dialogFilterVisiable: false,  //过滤弹窗是否显示
        curr:null,
        inputing: false,
        timer: null,
        code: null,
        remark:"",
        errType:null,
        errs:[],
        autoPrint: false,
    }
  }, 
  computed: {
    isMinScreen: {  //是否小屏幕，浏览器
        get() {
          return this.$store.state.isMinScreen;
        }
    },
    docHeight: {  //表格的高度
        get() {
          var height =  this.$store.state.docHeight;
          return height - 320;
        }
    },
    inputCode: {
      get(){
        return this.code;
      },
      set(newValue){
        this.preCode = this.code;
        this.code = newValue;
      }
    },
  },
  methods: {
    getErrss: function(start){ //获取列表

      var _this = this;

      var param = this.$kit.rebuildQuery(this.query);
      if(start){
        this.currentPage = 1;
        param.start = 0;
      }


      this.axios.post(this.$kit.api.expErrorMessageList, param).then(function (result) {
        if(result.status){
          if(_this.errs.length>0)
            _this.errs.splice(0, _this.errs.length);
          
          _this.total = result.data.recordsTotal;
          var orderDatas = result.data.data;

          for(var i=0; i<orderDatas.length; i++){
            var item = orderDatas[i];
            if(item.created){
              item.created = item.created.substring(0,10);
            }

            _this.errs.push(item);
          } 

        }else{
          _this.$message(result.msg);
        }
        
      }).catch(function (error) {
        console.error(error);
      });

    },//end function
    refresh: function(){  //刷新列表
      this.$kit.clearQuery(this.query);
      this.currentPage = 1;
      this.getErrss();
    },
    handleSizeChange(val) {  //分页的每页数据变化后的回调事件
      //console.log(`每页 ${val} 条`);
      this.query.length = val;
      this.query.start = 0;
      this.getErrss();
    },
    handleCurrentChange(val) {   //分页的当前页改变后的回调事件
      //console.log(`当前页: ${val}`);
      this.query.start = this.query.length * (val -1);
      this.getErrss();
    },
    add(){  //添加
        var _this = this;
        var code1 = this.code;
        if(!code1){
          _this.$message(_this.msg('编号不能为空'));
          return;
        }
        var errmsg = {
            code:this.code,
            remark:this.remark,
            errType:this.errType
        }
        this.$axios.post(this.$kit.api.expErrorMessageCreate,errmsg)
            .then(function(result){
                if(result.status){
                  _this.$message(result.msg || _this.msg('添加成功'));
                  _this.getErrss();
                  _this.clearInputer();
                }else{
                    _this.$message(result.msg || _this.msg('添加失败'));
                }
               
            });
    },
    clearInputer(){
      this.code = null;
      this.prevCode = null;
    },
    autoSearch(){
      var _this = this;
      //debugger;
      if(this.inputing == false){
        this.inputing = true;
        this.actived = 'code';
        this.timer = setInterval(()=>{
          if(_this.inputing && _this.code){
            if(_this.prevCode == _this.code){
              clearInterval(_this.timer);
              _this.timer = null;
              _this.inputing = false;
              _this.add();
            }else{
              _this.prevCode = _this.code;
            }
          }
          
        }, 500);
      }
    },
    deleted(){
        var selectes = this.$refs.dataTable.getCheckboxRecords();
      if(selectes.length==0){
        this.$message(this.msg('请选中一条数据进行操作'));
        return;
      }

      var ids = selectes.map( o => o.id);
      
      var _this = this;

      this.$confirm(this.msg('确定要删除该异常信息吗?'), this.msg('提示'), {
          confirmButtonText: this.msg('确定'),
          cancelButtonText: this.msg('取消'),
          type: 'warning'
        }).then(() => {
          _this.$axios.post(_this.$kit.api.expErrorMessageDelete, ids)
            .then(function(result){
              _this.$message(result.msg || _this.msg('删除成功'));
              _this.getErrss();
          });
        });
    },
  }
}
</script>

<style class="scss" scoped>


</style>
